import { Span } from '@playbooks/interface/html';
import { BorderLink, Link } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const DemoBtn = ({ type = 'primary', model, loading = false }) => {
	const props = { model, loading };

	// Render
	switch (type) {
		case 'icon':
			return <DemoIconBtn {...props} />;
		case 'primary':
			return <DemoPrimaryBtn {...props} />;
		case 'secondary':
			return <DemoSecondaryBtn {...props} />;
	}
};

const DemoIconBtn = ({ model, loading }) => (
	<BorderLink
		size='icon'
		icon='arrow-up-right'
		alt='view demo'
		href={model.demoUrl}
		disabled={!model.demoUrl || model.demoType === 'none'}
		target='_blank'
	/>
);

const DemoPrimaryBtn = ({ model, loading }) => (
	<Link
		nextIcon='arrow-up-right'
		alt='view demo'
		href={model.demoUrl}
		disabled={!model.demoUrl || model.demoType === 'none'}
		target='_blank'>
		{loading ? <Skeleton className='w-20' /> : <Span>View Demo</Span>}
	</Link>
);

const DemoSecondaryBtn = ({ model, loading }) => (
	<BorderLink
		nextIcon='arrow-up-right'
		alt='view demo'
		href={model.demoUrl}
		disabled={!model.demoUrl || model.demoType === 'none'}
		target='_blank'>
		{loading ? <Skeleton className='w-20' /> : <Span>View Demo</Span>}
	</BorderLink>
);

export { DemoBtn };
